<template>
  <div class="mcc_service con_area_bgcolor">
    <div class="mcc_project_con con_area">
      <div class="w11402">
        <h2>
          <span class="title">Community Service and Consultancy</span>
        </h2>
        <div>
          <el-tabs
            :tab-position="cWidth > 1024 ? 'left' : 'top'"
            style="min-height: 300px"
          >
            <el-tab-pane label="A Trust in Need is the Trust indeed">
              <h1>A Trust in Need is the Trust indeed</h1>
              <!-- <h1>需要的信任就是真正的信任</h1> -->
              <p class="justify_align">
                Project is designed to create a supportive and welcoming
                environment for new Chinese immigrants in our community, and to
                help them integrate into our society smoothly and successfully.
                We believe that by providing them with the assistance and
                resources they need, we can foster a stronger and more connected
                community.
              </p>
              <ul>
                <li>
                  <i class="iconfont icon-trust"></i>
                  English/Chinese translation and interpretation services, The
                  Trust acts as a liaison between the government and the
                  community to ensure that non-English speakers can fully
                  utilize the services provided by the government,
                  agents,authorities and local business.
                </li>
                <li>
                  <i class="iconfont icon-trust"></i>
                  To recommend professional institutions to those in need.
                  Ensuring people with updated information and advice, such as
                  legal, immigration, accounting, business opportunities, trade
                  and investment, medical and insurance.
                </li>
                <li>
                  <i class="iconfont icon-trust"></i>
                  To those new arrivals who are facing settlement difficulties,
                  basic requirements, and business inquiries. We aim to help
                  them with house renting, opening bank account, arranging
                  electricity or telephone connections, school enrollments, and
                  other daily necessities.
                </li>
                <li>
                  <i class="iconfont icon-trust"></i>
                  Offer a premise as a community activities center to facilitate
                  people meeting together and enjoying the pleasures of
                  retrospection. This center will provide a space for people to
                  fulfill their emotional and spiritual needs through reading,
                  movies, music, chatting, games, and other activities.
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="Community Peace and Safety">
              <h1>Community Peace and Safety</h1>
              <!-- <h1>社区和平与安全</h1> -->
              <p class="justify_align">
                “Living and working in peace and contentment ” is the one of the
                aspirations in our Chinese community. As the world enter the
                post-Covid-19 era, we all are experiencing the recurrence of the
                new virus, climate change, floods and earthquakes.
              </p>
              <p class="justify_align">
                Coupled with the economic recession, high cost of living impact
                significantly on most vulnerable in the community -- housing
                crisis and community safety etc.
              </p>
              <p class="justify_align">
                Mcct believe that only by improving community resilience can we
                truly enhance and support our aspirations to overcome the
                adversity:
              </p>
              <ul class="justify_align">
                <li>
                  <i class="iconfont icon-anquan"></i>
                  Training sessions to educate community residents on how to
                  respond to various emergencies, such as earthquakes, fires,
                  etc.
                </li>
                <li>
                  <i class="iconfont icon-anquan"></i>
                  To have health experts to provide health consultation and
                  assessment services for residents to promote health management
                  and prevention programs.
                </li>
                <li>
                  <i class="iconfont icon-anquan"></i>
                  Work with the CPNZ (police-Community Patrol NZ) by organizing
                  volunteers to conduct regular community patrols to detect and
                  deal with possible safety hazards in a timely manner.
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="Care about our elderly Guardian of the Youth">
              <h1>Care about our elderly Guardian of the Youth</h1>
              <!-- <h1>关心我们年迈的青春守护者</h1> -->
              <p class="justify_align">
                Many Chinese families prefer to take care of their elderly at
                home, which is a cultural norm in China. However, in New
                Zealand, it is common for sons, daughters, and grandchildren to
                have busy lives as income earners and full-time students,
                leaving many elderly people isolated and alone.
              </p>
              <ul class="justify_align">
                <li>
                  <i class="iconfont icon-trust"></i>
                  To assist families adjusting to life in New Zealand, we offer
                  seminars on elderly care, which cover the differences in
                  approaches, legal frameworks, availability of rest homes and
                  respite care facilities, costs, contracts, finance, support,
                  and the importance of personal responsibility. We also assist
                  in connecting families with regional social services.
                </li>
                <li>
                  <i class="iconfont icon-trust"></i>
                  To create a safe and engaging social environment for the
                  elderly is a priority, particularly for those who lack
                  confidence in their language skills to integrate into New
                  Zealand society.
                </li>
                <li>
                  <i class="iconfont icon-trust"></i>
                  To facilitate and create activities that the elderly and their
                  families can enjoy together, including Mah-jong, board games,
                  Scrabble, line/folk dancing, Taiji, and also offer services
                  such as haircuts, pedicures, and massages.
                </li>
              </ul>
              <p class="justify_align">
                To the Youth in our community, our services will be provided
                through community education programs, support groups, case
                management, advocacy, and referrals. Services will be delivered
                as follows:
              </p>
              <ul class="justify_align">
                <li>
                  <i class="iconfont icon-trust"></i>
                  After-school programs for supporting youth, such as academic
                  counseling on school subjects, study assistance groups, and
                  recreational activities like art and handicraft making, table
                  tennis, and Chinese language and calligraphy learning.
                </li>
                <li>
                  <i class="iconfont icon-trust"></i>
                  Social skills improvement groups to empower children in
                  aspects of communication skills, anger management, time
                  management, and self-care skills to build children's
                  well-being during their childhood.
                </li>
              </ul>
              <p class="justify_align">
                Parenting programs that connect parents with similar needs into
                small mutual groups. We will support the skills and emotional
                needs of caregivers or parents with different discussion topics
                or parenting skills enhancement. Parents will also have the
                opportunity to meet and form a support network among each other.
              </p>
            </el-tab-pane>
            <el-tab-pane label="Working together">
              <h1>Working together</h1>
              <!-- <h1>一起工作</h1> -->
              <p class="justify_align">
                Participates in various culture activities hosted by city
                council, local government and community agencies, ethnic groups
                and/or other community organisations in which the cultural
                diversity is promoted. That is where we learn from, enjoy and
                grow in understanding of each other.
              </p>
              <ul class="justify_align">
                <li>
                  <i class="iconfont icon-shichanghuodong"></i
                  ><span>Festival of Culture</span> (held by PNCC) has been
                  proved to be a very good opportunity to promote The Trust and
                  Chinese culture. Chinese Traditional Costume Display and Folk
                  Dances will be performed on centre stage. Their demonstration
                  and performance again will be a very highlight of the Festival
                  Day Participate local.
                </li>
                <li>
                  <i class="iconfont icon-shichanghuodong"></i
                  ><span>Lantern Festival</span>, the 15th day of 1st lunar
                  month. Lantern parade and lion dance celebrating the first
                  full moon.
                </li>
                <li>
                  <i class="iconfont icon-shichanghuodong"></i>
                  Participate local annual event
                  <span>Lantern Festival</span> host by local artists. Sharing
                  Chinese Lanterns stories and folk dance performance at a
                  celebrating night.
                </li>
                <li>
                  <i class="iconfont icon-shichanghuodong"></i
                  ><span>Duan wu Festival</span>, 5th day of 5th lunar month,
                  people eat dumplings wrapped in lotus leaves. Dragon boat race
                  is also referred as one of a symbols of Chinese traditional
                  sports.
                </li>
                <li>
                  <i class="iconfont icon-shichanghuodong"></i>
                  <span>Christmas Parade</span> is a PNCC’s annual activity. The
                  MCCT will organise the Chinese cultural team to performance
                  there.
                </li>
                <li>
                  <i class="iconfont icon-shichanghuodong"></i>
                  Collaboration between the
                  <span>social welfare organizations</span> of Age Concern,
                  Disability Connect, Kahui Tu Kaha, Stroke Foundation NZ, Asian
                  Family Services. We set a goal to provide supports to local
                  Asians and families for their mental health, dealing with
                  racial discrimination and/or physical disabilities.
                </li>
                <li>
                  <i class="iconfont icon-shichanghuodong"></i>
                  <span>Chinese Language Week</span> an annually event proposed
                  and promoted by New Zealand & China Friendship Society assures
                  us the opportunity to commit the Trust of Community Education
                  programs on Chinese Language and Culture showcases.
                </li>
              </ul>
              <p class="justify_align">
                MCCT enjoys a high level of cooperation with and input from
                other agencies and organizations which will help us to form a
                stronger synergetic support network.
              </p>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cWidth: sessionStorage.getItem("clientWidth"),
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style>
</style>